import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import SEO from "@components/seo";
// import PageBreadcrumb from "../../components/pagebreadcrumb";
// import Profile from "../../components/Profile";
import { normalizedData } from "@utils/functions";
import jsonDataInit from "../../data/products/health-insurance.json";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const Health = ({ data, location, pageContext }) => {
    const [jsonData, setJsonData] = useState(jsonDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a732d478fc357870f09034"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Health Insurance" pathname="/" />
            <Container style={{ lineHeight: "130%" }}>
                <img
                    style={{
                        marginTop: "10px",
                        marginBottom: "30px",
                        borderRadius: "10px",
                    }}
                    src={jsonData[0].image}
                    alt="Health"
                />
                <div
                    style={{
                        marginBottom: "30px",
                    }}
                >
                    <h3>{jsonData[0].title}</h3>
                    <p
                        style={{
                            textAlign: "justify",
                            color: "#000",
                            fontSize: "17px",
                        }}
                    >
                        {jsonData[0].title1}
                    </p>
                    <ul>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[0].title2}
                        </li>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[0].text}
                        </li>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[0].text1}
                        </li>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[0].text2}
                        </li>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].title}
                        </li>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].title1}
                        </li>
                        <li
                            style={{
                                fontSize: "15px",
                                marginLeft: "20px",
                                color: "#000",
                            }}
                        >
                            {jsonData[1].title2}
                        </li>
                    </ul>
                    <p
                        style={{
                            textAlign: "justify",
                            color: "#000",
                            fontSize: "20px",
                        }}
                    >
                        {jsonData[1].text}
                    </p>
                    <img
                        style={{
                            marginTop: "10px",
                        }}
                        src={jsonData[1].image}
                        alt="Health"
                    />
                    <p style={{ textAlign: "center", marginTop: "0px" }}>
                        Health Insurance Benefits Schedule
                    </p>
                    <p style={{ color: "#000", fontSize: "18px" }}>
                        For details, you are requested to contact to our office.
                    </p>
                </div>
            </Container>
        </Layout>
    );
};
Health.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query HealthQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default Health;
